import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import LazyLoad from "react-lazyload";

const ShantiDhara = () => {
  return (
    <>
      <Header />
      <Breadcrumb
        pageTitle="Flowers In Heaven"
        breadcrumbName="Flowers In Heaven"
      />
      <div className="innerpage">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title"> Death Anniversary of Our Sisters </h2>
            </div>
             <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr.sunila.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Sunila
                  <span>
                    Born to Eternal Heaven
                    <br />
                    09.03.2025
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr.lata.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Lata
                  <span>
                    Born to Eternal Heaven
                    <br />
                    21.03.2023
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Neena.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Neena
                  <span>
                    Born to Eternal Heaven
                    <br />
                    01.01.2023
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Grace.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Grace
                  <span>
                    Born to Eternal Heaven
                    <br />
                    17.01.2020
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Irene.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Irene
                  <span>
                    Born to Eternal Heaven
                    <br />
                    04.02.2016
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr.-Anna.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Anna
                  <span>
                    Born to Eternal Heaven
                    <br /> 24.02.1995
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Seema.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Seema
                  <span>
                    Born to Eternal Heaven
                    <br /> 26.02.2017
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Jaya.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Jaya
                  <span>
                    Born to Eternal Heaven
                    <br /> 13.04.2012
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr.Angela.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Angela
                  <span>
                    Born to Eternal Heaven
                    <br /> 20.04.1984
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Litty.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Litty
                  <span>
                    Born to Eternal Heaven
                    <br /> 09.05.2021
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Prasanna.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Prasanna
                  <span>
                    Born to Eternal Heaven
                    <br /> 10.06.2014
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Shobana.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Shobhana
                  <span>
                    Born to Eternal Heaven
                    <br /> 22.05.2020
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Elsy.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Elsy
                  <span>
                    Born to Eternal Heaven
                    <br /> 12.07.2017
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr.-Ancy.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Ancy
                  <span>
                    Born to Eternal Heaven
                    <br />
                    29.07.1999
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Alicia.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Alicia
                  <span>
                    Born to Eternal Heaven
                    <br />
                    01.08.2000
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Vincy.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Vincy
                  <span>
                    Born to Eternal Heaven
                    <br />
                    12.08.1991
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Margaret.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Margaret
                  <span>
                    Born to Eternal Heaven
                    <br />
                    29.09.2016
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr.-Colette.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Collette
                  <span>
                    Born to Eternal Heaven
                    <br />
                    30.10.1983
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr.-Rosa.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Rosa
                  <span>
                    Born to Eternal Heaven
                    <br />
                    01.11.1986
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Aurelia.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Aurelia
                  <span>
                    Born to Eternal Heaven
                    <br />
                    07.11.2018
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr.-Cecilia.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Cecilia
                  <span>
                    Born to Eternal Heaven
                    <br />
                    15.11.1985
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Felicity.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Felicity
                  <span>
                    Born to Eternal Heaven
                    <br />
                    17.11.2015
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr.-Josephine.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Josephine
                  <span>
                    Born to Eternal Heaven
                    <br />
                    25.11.1995
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr. Bernedette.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Bernadette
                  <span>
                    Born to Eternal Heaven
                    <br />
                    17.12.2006
                  </span>
                </p>
              </div>
            </div>
            <div className=" col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <div className="flowerboximg">
                <LazyLoad offset={10}>
                  <img
                    src="https://webapi.entab.info/api/image/FSOLG/public/Images/Sr.Sophy.jpg"
                    alt="Franciscan Sisters of Our Lady Of Graces"
                    className="img-fluid"
                  />
                </LazyLoad>
                <p>
                  Sr. Sophy
                  <span>
                    Born to Eternal Heaven
                    <br /> 28.12.2018
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ShantiDhara;
